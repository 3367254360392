import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  role: "navigation",
  "aria-label": "Pagination Navigation",
  class: "flex items-center justify-between"
}
const _hoisted_2 = {
  id: "mobileMenu",
  class: "flex justify-between flex-1 hidden"
}
const _hoisted_3 = {
  key: 0,
  "aria-disabled": "true",
  class: "cursor-not-allowed opacity-50 relative inline-flex items-center px-4 py-2 text-xs font-medium text-gray-500 bg-white border border-gray-300 leading-5 rounded-md"
}
const _hoisted_4 = {
  key: 3,
  "aria-disabled": "true",
  class: "cursor-not-allowed opacity-50 relative inline-flex items-center px-4 py-2 ml-3 text-xs font-medium text-gray-500 bg-white border border-gray-300 leading-5 rounded-md"
}
const _hoisted_5 = { class: "flex-1 flex items-center justify-between" }
const _hoisted_6 = {
  id: "frontText",
  class: "sm:hidden mr-3"
}
const _hoisted_7 = { class: "text-xs text-gray-700 leading-5" }
const _hoisted_8 = { class: "font-medium" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = {
  id: "frontText",
  class: "hidden sm:block sm:w-full text-right mr-3"
}
const _hoisted_11 = { class: "text-xs text-gray-700 leading-5" }
const _hoisted_12 = { class: "font-medium" }
const _hoisted_13 = { class: "font-medium" }
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = { class: "relative z-0 inline-flex shadow-sm rounded-md" }
const _hoisted_16 = {
  key: 0,
  "aria-disabled": "true",
  "aria-label": "Previous"
}
const _hoisted_17 = {
  key: 3,
  "aria-disabled": "true",
  "aria-label": "Next"
}

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationComponent',
  props: {
    data: {
        type: Object,
        default: () => ({}),
    },
},
  emits: ["menu-click"],
  setup(__props, { emit: __emit }) {

const props = __props;


const toPage = computed(() => {
    const currentPage = props.data.currentPage;
    const perPage = props.data.perPage;

    if (props.data.currentPage == props.data.lastPage) {
        return props.data.total;
    } else {
        return currentPage * perPage;
    }
});

const fromPage = computed(() => {
    const currentPage = props.data.currentPage;
    const perPage = props.data.perPage;
    return currentPage * perPage - (perPage - 1);
});

const emit = __emit;

const nextClick = () => {
    const nextPage = props.data.currentPage + 1;
    emit("menu-click", nextPage);
};

const prevClick = () => {
    const prevPage = props.data.currentPage - 1;
    emit("menu-click", prevPage);
};


return (_ctx: any,_cache: any) => {
  return (__props.data.total > 3)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (__props.data.currentPage <= 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Previous "))
            : (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "relative inline-flex items-center px-4 py-2 text-xs font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",
                onClick: prevClick
              }, " Previous ")),
          (__props.data.currentPage < __props.data.lastPage)
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                class: "relative inline-flex items-center px-4 py-2 ml-3 text-xs font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",
                onClick: nextClick
              }, " Next "))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, " Next "))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(fromPage.value + "-" + toPage.value), 1),
              _cache[0] || (_cache[0] = _createTextVNode(" of ")),
              _createElementVNode("span", _hoisted_9, _toDisplayString(__props.data.total), 1),
              _cache[1] || (_cache[1] = _createTextVNode(" results "))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, [
              _cache[2] || (_cache[2] = _createTextVNode(" Showing ")),
              _createElementVNode("span", _hoisted_12, _toDisplayString(fromPage.value), 1),
              _cache[3] || (_cache[3] = _createTextVNode(" to ")),
              _createElementVNode("span", _hoisted_13, _toDisplayString(toPage.value), 1),
              _cache[4] || (_cache[4] = _createTextVNode(" of ")),
              _createElementVNode("span", _hoisted_14, _toDisplayString(__props.data.total), 1),
              _cache[5] || (_cache[5] = _createTextVNode(" results "))
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_15, [
              (props.data.currentPage <= 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _cache[6] || (_cache[6] = [
                    _createElementVNode("span", {
                      "aria-disabled": "true",
                      class: "cursor-not-allowed opacity-30 bg-gray-100 relative inline-flex items-center px-2 py-1 text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-l-md leading-5",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("svg", {
                        class: "w-4 h-4",
                        fill: "currentColor",
                        viewBox: "0 0 20 20"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
                          "clip-rule": "evenodd"
                        })
                      ])
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    rel: "prev",
                    class: "bg-white relative inline-flex items-center px-2 py-1 text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-l-md leading-5 hover:bg-gray-300 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition-colors ease-in-out duration-100",
                    "aria-label": "Previous",
                    onClick: prevClick
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("svg", {
                      class: "w-4 h-4",
                      fill: "currentColor",
                      viewBox: "0 0 20 20"
                    }, [
                      _createElementVNode("path", {
                        "fill-rule": "evenodd",
                        d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
                        "clip-rule": "evenodd"
                      })
                    ], -1)
                  ]))),
              (props.data.currentPage < props.data.lastPage)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    rel: "next",
                    class: "bg-white relative inline-flex items-center px-2 py-1 -ml-px text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-r-md leading-5 hover:bg-gray-300 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition-colors ease-in-out duration-100",
                    "aria-label": "Next",
                    onClick: nextClick
                  }, _cache[8] || (_cache[8] = [
                    _createElementVNode("svg", {
                      class: "w-4 h-4",
                      fill: "currentColor",
                      viewBox: "0 0 20 20"
                    }, [
                      _createElementVNode("path", {
                        "fill-rule": "evenodd",
                        d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                        "clip-rule": "evenodd"
                      })
                    ], -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_17, _cache[9] || (_cache[9] = [
                    _createElementVNode("span", {
                      "aria-disabled": "true",
                      class: "cursor-not-allowed opacity-30 bg-gray-100 relative inline-flex items-center px-2 py-1 -ml-px text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-r-md leading-5",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("svg", {
                        class: "w-4 h-4",
                        fill: "currentColor",
                        viewBox: "0 0 20 20"
                      }, [
                        _createElementVNode("path", {
                          "fill-rule": "evenodd",
                          d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                          "clip-rule": "evenodd"
                        })
                      ])
                    ], -1)
                  ])))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})