<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
});


const toPage = computed(() => {
    const currentPage = props.data.currentPage;
    const perPage = props.data.perPage;

    if (props.data.currentPage == props.data.lastPage) {
        return props.data.total;
    } else {
        return currentPage * perPage;
    }
});

const fromPage = computed(() => {
    const currentPage = props.data.currentPage;
    const perPage = props.data.perPage;
    return currentPage * perPage - (perPage - 1);
});

const emit = defineEmits(["menu-click"]);

const nextClick = () => {
    const nextPage = props.data.currentPage + 1;
    emit("menu-click", nextPage);
};

const prevClick = () => {
    const prevPage = props.data.currentPage - 1;
    emit("menu-click", prevPage);
};

</script>

<template>
    <nav v-if="data.total > 3" role="navigation" aria-label="Pagination Navigation" class="flex items-center justify-between">
        <!-- Pagination for mobile devices -->
        <div id="mobileMenu" class="flex justify-between flex-1 hidden">
            <span
                v-if="data.currentPage <= 1"
                aria-disabled="true"
                class="cursor-not-allowed opacity-50 relative inline-flex items-center px-4 py-2 text-xs font-medium text-gray-500 bg-white border border-gray-300 leading-5 rounded-md"
            >
                Previous
            </span>
            <a
                v-else
                class="relative inline-flex items-center px-4 py-2 text-xs font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                @click="prevClick"
            >
                Previous
            </a>

            <a
                v-if="data.currentPage < data.lastPage"
                class="relative inline-flex items-center px-4 py-2 ml-3 text-xs font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                @click="nextClick"
            >
                Next
            </a>
            <span
                v-else
                aria-disabled="true"
                class="cursor-not-allowed opacity-50 relative inline-flex items-center px-4 py-2 ml-3 text-xs font-medium text-gray-500 bg-white border border-gray-300 leading-5 rounded-md"
            >
                Next
            </span>
        </div>

        <!-- Pagination -->
        <div class="flex-1 flex items-center justify-between">

            <!-- MOBILE DEVICES -->
            <div id="frontText" class="sm:hidden mr-3">
                <p class="text-xs text-gray-700 leading-5">
                    <span class="font-medium">{{ fromPage + "-" + toPage}}</span>
                    of
                    <span class="font-bold">{{ data.total }}</span>
                    results
                </p>
            </div>
            <!-- Text for desktop devices -->
            <div id="frontText" class="hidden sm:block sm:w-full text-right mr-3">
                <p class="text-xs text-gray-700 leading-5">
                    Showing
                    <span class="font-medium">{{ fromPage }}</span>
                    to
                    <span class="font-medium">{{ toPage }}</span>
                    of
                    <span class="font-bold">{{ data.total }}</span>
                    results
                </p>
            </div>

            <div>
                <span class="relative z-0 inline-flex shadow-sm rounded-md">
                    <!-- PREVIOUS BUTTON =========================================================================================== -->
                    <!-- Disabled button if less than 2 pages -->
                    <span v-if="props.data.currentPage <= 1" aria-disabled="true" aria-label="Previous">
                        <span
                            aria-disabled="true"
                            class="cursor-not-allowed opacity-30 
                            bg-gray-100 relative inline-flex items-center px-2 py-1 text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-l-md leading-5"
                            aria-hidden="true"
                        >
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fill-rule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                    </span>
                    <!-- The button is enabled if there is more than 1 page -->
                    <a
                        v-else
                        rel="prev"
                        class="bg-white relative inline-flex items-center px-2 py-1 text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-l-md leading-5 hover:bg-gray-300 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition-colors ease-in-out duration-100"
                        aria-label="Previous"
                        @click="prevClick"
                    >
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>

                    <!-- NEXT BUTTON =========================================================================================== -->
                    <a
                        v-if="props.data.currentPage < props.data.lastPage"
                        rel="next"
                        class="bg-white relative inline-flex items-center px-2 py-1 -ml-px text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-r-md leading-5 hover:bg-gray-300 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition-colors ease-in-out duration-100"
                        aria-label="Next"
                        @click="nextClick"
                    >
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>
                    <!-- else disabled link -->
                    <span v-else aria-disabled="true" aria-label="Next">
                        <span
                            aria-disabled="true"
                            class="cursor-not-allowed opacity-30 
                            bg-gray-100 relative inline-flex items-center px-2 py-1 -ml-px text-sm font-medium text-gray-500 border border-gray-300 shadow-sm rounded-r-md leading-5 "
                            aria-hidden="true"
                        >
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        
    </nav>
</template>
