import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/site/img/re3-data-logo-mono.jpg'
import _imports_1 from '@/assets/site/img/geosphere-austria-logo.jpg'
import _imports_2 from '@/assets/site/img/base_logo.png'


const _hoisted_1 = {
  id: "page_style",
  class: "flex flex-col items-center w-full h-full bg-transparent overflow-hidden",
  autocomplete: "off"
}
const _hoisted_2 = { class: "search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]" }
const _hoisted_3 = { class: "hidden sm:flex w-full" }
const _hoisted_4 = { class: "bg-[#eff3f7] h-12 rounded-md md:w-8/12 lg:w-9/12 mt-3 ml-5 mr-4 pl-3 flex items-center justify-between" }
const _hoisted_5 = {
  key: 0,
  class: "px-2 py-1 text-xs bg-white rounded-md",
  role: "alert"
}
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "sm:hidden w-full" }
const _hoisted_8 = {
  key: 0,
  class: "mx-6 my-4"
}
const _hoisted_9 = { class: "bg-[#eff3f7] rounded-md mx-5 p-2 my-4 flex items-center justify-between" }
const _hoisted_10 = {
  key: 0,
  class: "px-2 py-1 text-xs bg-white rounded-md",
  role: "alert"
}
const _hoisted_11 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_12 = {
  key: 1,
  class: ""
}
const _hoisted_13 = ["title"]
const _hoisted_14 = { class: "flex flex-col md:flex-row w-full" }
const _hoisted_15 = { class: "w-full md:w-4/12 lg:w-3/12 text-sm px-5 pt-5" }
const _hoisted_16 = { id: "externals" }
const _hoisted_17 = { class: "flex flex-col md:w-8/12 lg:w-9/12 text-sm p-4" }
const _hoisted_18 = {
  key: 0,
  class: "hidden sm:block mb-4"
}
const _hoisted_19 = { class: "results" }
const _hoisted_20 = { class: "sm:hidden flex w-full" }
const _hoisted_21 = { class: "bg-[#eff3f7] h-12 rounded-md my-3 mx-2 flex w-full items-center justify-between" }
const _hoisted_22 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vs_input = _resolveComponent("vs-input")!
  const _component_PaginationComponent = _resolveComponent("PaginationComponent")!
  const _component_active_facet_category = _resolveComponent("active-facet-category")!
  const _component_facet_category = _resolveComponent("facet-category")!
  const _component_vs_result = _resolveComponent("vs-result")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vs_input, {
        propDisplay: _ctx.searchTerm,
        placeholder: 'Enter your search term...',
        onSearchChange: _ctx.onSearch
      }, null, 8, ["propDisplay", "onSearchChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "md:w-4/12 lg:w-3/12" }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasSearchTerm())
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-medium" }, "Your search term ", -1)),
              _createElementVNode("span", _hoisted_6, _toDisplayString("'" + _ctx.stringSearchTerm + "'"), 1),
              _cache[3] || (_cache[3] = _createTextVNode(" yielded ")),
              _createElementVNode("strong", null, _toDisplayString(_ctx.results.length > 0 ? _ctx.numFound : 0), 1),
              _cache[4] || (_cache[4] = _createTextVNode(" results: "))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_PaginationComponent, {
          class: "pr-2 ml-auto",
          data: _ctx.pagination,
          onMenuClick: _ctx.onMenuClick
        }, null, 8, ["data", "onMenuClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.activeFilterCategories && Object.keys(_ctx.activeFilterCategories).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeFilterCategories, (values, key, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: "active-filter-items"
              }, [
                _createVNode(_component_active_facet_category, {
                  filterItems: values,
                  categoryName: key,
                  onClearFacetCategory: _ctx.onClearFacetCategory,
                  class: "text-xs"
                }, null, 8, ["filterItems", "categoryName", "onClearFacetCategory"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        true
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.hasSearchTerm())
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString("'" + _ctx.stringSearchTerm + "' "), 1))
                : _createCommentVNode("", true),
              (_ctx.hasSearchTerm())
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, "offers "))
                : _createCommentVNode("", true),
              _createElementVNode("strong", null, _toDisplayString(_ctx.results.length > 0 ? _ctx.numFound : 0), 1),
              _cache[6] || (_cache[6] = _createTextVNode(" results "))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("a", {
          class: "inline-block px-2 py-1 ml-2 mr-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-xs font-normal text-primary-tethys rounded cursor-pointer",
          title: _ctx.$t('go_to_results')
        }, [
          _createElementVNode("i", {
            class: "fas fa-arrow-down",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToResults()))
          })
        ], 8, _hoisted_13)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facets, (facetItems, key, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "mb-0"
            }, [
              _createVNode(_component_facet_category, {
                facetItems: facetItems,
                filterName: key,
                onFilter: _ctx.onFilter
              }, null, 8, ["facetItems", "filterName", "onFilter"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        (_ctx.activeFilterCategories && Object.keys(_ctx.activeFilterCategories).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeFilterCategories, (values, key, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: index,
                  class: "active-filter-items"
                }, [
                  _createVNode(_component_active_facet_category, {
                    filterItems: values,
                    categoryName: key,
                    onClearFacetCategory: _ctx.onClearFacetCategory
                  }, null, 8, ["filterItems", "categoryName", "onClearFacetCategory"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("a", {
                class: "inline-block px-2 py-1 ml-2 mr-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-xs font-normal text-primary-tethys rounded cursor-pointer",
                title: _ctx.$t('go_to_search_bar')
              }, [
                _createElementVNode("i", {
                  class: "fas fa-arrow-up",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollToTop()))
                })
              ], 8, _hoisted_22),
              _createVNode(_component_PaginationComponent, {
                class: "pr-2 ml-auto",
                data: _ctx.pagination,
                onMenuClick: _ctx.onMenuClick
              }, null, 8, ["data", "onMenuClick"])
            ])
          ]),
          _createVNode(_component_vs_result, { datasets: _ctx.results }, null, 8, ["datasets"]),
          _createVNode(_component_PaginationComponent, {
            class: "mt-5",
            data: _ctx.pagination,
            onMenuClick: _ctx.onMenuClick
          }, null, 8, ["data", "onMenuClick"])
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"w-full mx-auto mt-5 px-4\" data-v-afd70970><div class=\"flex flex-wrap justify-between items-center\" data-v-afd70970><div class=\"flex justify-center w-full md:w-1/3 p-4\" data-v-afd70970><a target=\"_blank\" href=\"https://www.re3data.org/repository/r3d100013400\" data-v-afd70970><img src=\"" + _imports_0 + "\" alt=\"re3 data logo\" class=\"w-full max-w-[200px]\" data-v-afd70970></a></div><div class=\"flex justify-center w-full md:w-1/3 p-4\" data-v-afd70970><a target=\"_blank\" href=\"http://www.geosphere.at/\" data-v-afd70970><img src=\"" + _imports_1 + "\" alt=\"logo geosphere austria\" class=\"w-full max-w-[400px]\" data-v-afd70970></a></div><div class=\"flex justify-center w-full md:w-1/3 p-4\" data-v-afd70970><a target=\"_blank\" href=\"https://www.base-search.net/Search/Results?q=coll:fttethysrdr&amp;refid=dctablede\" data-v-afd70970><img src=\"" + _imports_2 + "\" alt=\"logo base\" class=\"w-full max-w-[250px]\" data-v-afd70970></a></div></div></div>", 1))
  ]))
}